<template>
  <div>
    <div class="title flexB">
      <h1>{{ $t("top-select-vod-manager-tc") }}</h1>
      <div>
        <!--        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >-->
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
      </div>
    </div>
    <div class="contents vodCont">
      <div class="box one filter">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select>
            <option value="">{{ $t("search-keyword-all") }}</option>
            <option value="">VOD ID</option>
            <option value="">{{ $t("tc-data-file-title") }}</option>
          </select>
          <input type="text" />
        </div>
        <div class="flex">
          <p class="bold">{{ $t("search-regdate-title") }}</p>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="flex">
          <p class="bold">{{ $t("tc-data-file-status") }}</p>
          <label
          ><input type="radio" name="status" checked />{{ $t("tc-data-file-status-all") }}</label
          >
          <label><input type="radio" name="status" />{{ $t("tc-data-file-status-processing") }}</label>
          <label><input type="radio" name="status" />{{ $t("tc-data-file-status-success") }}</label>
          <label><input type="radio" name="status" />{{ $t("tc-data-file-status-fail") }}</label>
        </div>
        <div class="buttonWrap">
          <button class="point large">{{ $t("btn-searching") }}</button>
          <button class="large margin6">{{ $t("btn-reset") }}</button>
        </div>
      </div>
      <div class="box one">
        <h2 class="bold">{{ $t("data-total") }} <span class="blue">143</span>{{ $t("data-case-ea") }}</h2>
        <!-- <div class="none">
            <p>{{ $t("search-not-found") }}</p>
          </div> -->
        <table>
          <tr>
            <th>{{ $t("tc-data-file-thum") }}</th>
            <th>VOD ID</th>
            <th>{{ $t("tc-data-file-quality") }}</th>
            <th>{{ $t("tc-data-file-id") }}</th>
            <th>{{ $t("tc-data-file-title") }}</th>
            <th>{{ $t("tc-data-file-play-time") }}</th>
            <th>{{ $t("tc-data-file-status") }}</th>
            <th>{{ $t("tc-data-file-request") }}</th>
            <th>{{ $t("tc-data-file-regdate") }}</th>
          </tr>
          <tr>
            <td>
              <div></div>
            </td>
            <td>VG001234</td>
            <td>720p</td>
            <td>FO10010001</td>
            <td>북한산_20210101_11</td>
            <td>14:32</td>
            <td>처리중</td>
            <td><button class="tablepoint">실행</button></td>
            <td>2021.01.01 01:19:10</td>
          </tr>
          <tr>
            <td>
              <div></div>
            </td>
            <td>VG001234</td>
            <td>720p</td>
            <td>FO10010001</td>
            <td>북한산_20210101_11</td>
            <td>14:32</td>
            <td>성공</td>
            <td><button class="tablepoint">실행</button></td>
            <td>2021.01.01 01:19:10</td>
          </tr>
          <tr>
            <td>
              <div></div>
            </td>
            <td>VG001234</td>
            <td>720p</td>
            <td>FO10010001</td>
            <td>북한산_20210101_11</td>
            <td>14:32</td>
            <td>성공</td>
            <td><button class="tablepoint">실행</button></td>
            <td>2021.01.01 01:19:10</td>
          </tr>
          <tr>
            <td>
              <div></div>
            </td>
            <td>VG001234</td>
            <td>720p</td>
            <td>FO10010001</td>
            <td>북한산_20210101_11</td>
            <td>14:32</td>
            <td>실패</td>
            <td><button class="tablepoint">실행</button></td>
            <td>2021.01.01 01:19:10</td>
          </tr>
        </table>
        <div class="pagination">
          <el-pagination small layout="prev, pager, next" :total="100">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

export default {
  components: { Datepicker },
  name: "Transcoding",
  data() {
    return {
      ko: ko,
      moment: moment,
      startYmd: "",
      endYmd: "",
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 6, sub: 1 });
  },
  mounted() {
    this.dateSetting();
  },
  methods: {
    dateSetting() {
      this.endYmd = new Date();
      let date = new Date();
      this.startYmd = date.setDate(date.getDate() - 14);
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleRefresh() {
      this.$router.go();
    },
  },
};
</script>
